import { useEffect, useState } from 'react';

const getStorageValue = <T>(key: string) => {
  const saved = localStorage.getItem(key);
  if (saved) {
    try {
      return JSON.parse(saved) as T;
    } catch (e) {}
  }
  return null;
};

const setStorageValue = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const useLocalStorage = <T>(key: string, defaultStorageState: T) => {
  const initStorageState = getStorageValue<T>(key);
  const [value, setValue] = useState<T>(initStorageState ?? defaultStorageState);
  useEffect(() => {
    setStorageValue(key, value);
  }, [key, value]);

  return [value, setValue] as const;
};

export { useLocalStorage, getStorageValue, setStorageValue };

import React, { useState } from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import Tabs, { Tab } from '@ingka/tabs';
import Container from 'components/common/Container';
import { useCountry } from 'hooks/useCountry';
import { useLatestByCountryQuery } from '__generated__/types';
import * as Styled from './styles';
import Text from '@ingka/text';
import { FlexContainer } from '../../components/FlexContainer';
import { RuleInvocations } from '../../features/Dashboard/RuleInvocations';
import { formatVersion, getSelectableLanguages } from '../../utils/version';
import { useLanguageSelection } from '../../hooks/contexts/LanguageContext';
import { LanguageDropdown } from '../../components/LanguageDropdown';

export const Dashboard: React.FC = () => {
  const [country] = useCountry();
  const [activeRedirectUsageTabKey, setActiveRedirectUsageTabKey] = useState('high');
  const countryCode = country?.countryCode;
  const { selectedLanguage } = useLanguageSelection();
  const { data, loading } = useLatestByCountryQuery({
    variables: { country: countryCode as string },
    skip: !countryCode,
  });
  const versionsForCountry = data?.latestVersionsForCountry;
  const formattedVersions = versionsForCountry?.map(formatVersion) ?? [];
  const selectableLanguages = getSelectableLanguages(formattedVersions, countryCode ? countryCode : '');
  const multiplePolicies = selectableLanguages.map(({ languageCode }) => `${countryCode}-${languageCode}`);
  const ruleUsageTabs = [
    { type: 'high', name: 'High' },
    { type: 'low', name: 'Low' },
  ];

  if (loading) {
    return (
      <Container>
        <Loading text={'Loading...'}>
          <LoadingBall />
        </Loading>
      </Container>
    );
  }

  return (
    <Container>
      <Styled.DashboardHeaderWrapper>
        <h2>Overview {selectedLanguage !== '*' && `- ${selectedLanguage.toUpperCase()}`}</h2>
        <LanguageDropdown countryCode={countryCode ?? ''} refreshRedirect={true} />
      </Styled.DashboardHeaderWrapper>
      <Styled.DashboardSectionWrapper direction={'column'}>
        <Styled.DashboardSection direction="column">
          <Text tagName={'h4'} headingSize={'xs'}>
            Redirect Usage
          </Text>
          <FlexContainer direction={'column'} mt={0.5}>
            <Tabs
              tabs={ruleUsageTabs.map(({ type, name }) => (
                <Tab key={type} tabPanelId={type} text={name} />
              ))}
              tabPanels={
                <RuleInvocations
                  policy={selectedLanguage}
                  direction={activeRedirectUsageTabKey === 'high' ? 'desc' : 'asc'}
                  multiplePolicies={selectedLanguage === '*' ? multiplePolicies : undefined}
                />
              }
              defaultActiveTab={activeRedirectUsageTabKey}
              onTabChanged={(selectedKey: string) => setActiveRedirectUsageTabKey(selectedKey)}
            />
          </FlexContainer>
        </Styled.DashboardSection>
      </Styled.DashboardSectionWrapper>
    </Container>
  );
};

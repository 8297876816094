import styled from '@emotion/styled';
import { radiusS, space100, space75, thicknessThin } from '@ingka/variables';
import { colourNeutral3 } from '@ingka/variables/colours-css';
import { FlexContainer } from '../../../components/FlexContainer';

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space100};
`;

export const DashboardSectionWrapper = styled(FlexContainer)`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  gap: ${space100};
`;

export const DashboardSection = styled(FlexContainer)`
  border: ${thicknessThin} solid ${colourNeutral3};
  border-radius: ${radiusS};
  padding: ${space75};
  height: 100%;
  gap: ${space100};
`;

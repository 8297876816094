import React, { useContext, useState } from 'react';
import Tabs from '@ingka/tabs';
import Text from '@ingka/text';
import { EditorCheckboxInput } from 'components/common/EditorCheckboxInput';
import { FlexContainer } from 'components/FlexContainer';
import { StatusIcon } from 'components/StatusIcon';
import { Context as EditorContext } from 'hooks/contexts/EditorContext';
import { EditSheetComments } from './EditSheetComments';
import * as Styled from './styles';
import { isPublicera } from '../../opt-util/helpers';

export const EditSheetContentSideBar = (): JSX.Element | null => {
  const {
    state: { formData, versionDetails },
  } = useContext(EditorContext);
  const [activeTab, setActiveTab] = useState('info');

  if (!versionDetails || !formData) return null;
  const isFilterPage = versionDetails.type === 'filter';
  const isPubliceraPage = isPublicera(versionDetails.type);
  const InfoTabPanel = () => {
    if (isPubliceraPage && formData.referenceUrl)
      return (
        <FlexContainer mt={1} direction="column" gap={0.1}>
          <Text headingSize="xs">Published page URL</Text>
          <Text>
            <Styled.LinkWithWordBreak href={formData.referenceUrl ?? ''} target="_blank" rel="noreferrer noopenner">
              {formData.referenceUrl}
            </Styled.LinkWithWordBreak>
          </Text>
        </FlexContainer>
      );
    return (
      <>
        <StatusIcon statusType={versionDetails?.type === 'filter' ? 'isPublished' : 'isOptimized'} status={!!formData.isOptimized} />
        <FlexContainer direction="column" gap={0.1}>
          <Text headingSize="xs">ID</Text>
          <Text>{formData.id}</Text>
        </FlexContainer>

        {isFilterPage && (
          <>
            <FlexContainer direction="column" gap={0.1}>
              <Text headingSize="xs">Filter Type</Text>
              <Text>{formData.filterType}</Text>
            </FlexContainer>

            <FlexContainer direction="column" gap={0.1}>
              <Text headingSize="xs">Filter Name</Text>
              <Text>{formData.filterName}</Text>
            </FlexContainer>
          </>
        )}

        {formData.ts && (
          <FlexContainer direction="column" gap={0.1}>
            <Text headingSize="xs">Updated At</Text>
            <FlexContainer gap={0.3}>
              <Text>{new Date(formData.ts).toLocaleDateString()},</Text>
              <Text>
                {new Date(formData.ts).toLocaleTimeString(undefined, {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                })}
              </Text>
            </FlexContainer>
          </FlexContainer>
        )}

        <FlexContainer direction="column" gap={0.1}>
          <Text headingSize="xs">Updated By</Text>
          <Text>{formData.author}</Text>
        </FlexContainer>
        {isFilterPage && (
          <FlexContainer direction="column" mt={1} gap={0.1}>
            <EditorCheckboxInput id="isOptimized" label="Published" />
            <EditorCheckboxInput id="descriptionIsSynced" label="Sync OG Title and Description" />
          </FlexContainer>
        )}
      </>
    );
  };

  const tabsProps = [
    { tabPanelId: 'info', text: 'Info', children: <InfoTabPanel /> },
    {
      tabPanelId: 'comments-tab',
      text: 'Comments',
      children: (
        <EditSheetComments
          country={versionDetails.market.country}
          language={versionDetails.market.language}
          type={versionDetails.type}
          id={formData.id}
          isActiveTab={activeTab === 'comments-tab'}
        />
      ),
    },
  ];

  const tabs = tabsProps.map((tab) => <Styled.ModalSideBarTab key={tab.tabPanelId} tabPanelId={tab.tabPanelId} text={tab.text} />);

  const tabPanels = tabsProps.map((tabPanel) => (
    <Styled.ModalSideBarTabPanel key={tabPanel.tabPanelId} tabPanelId={tabPanel.tabPanelId}>
      {tabPanel.children}
    </Styled.ModalSideBarTabPanel>
  ));

  return <Tabs activeTab={activeTab} onTabChanged={setActiveTab} overflowCarouselProps={{ hasNoControls: true }} tabs={tabs} tabPanels={tabPanels} />;
};

import React, { useContext } from 'react';
import * as Styled from './styles';
import { Option } from '@ingka/select';
import { useLanguageSelection } from '../../hooks/contexts/LanguageContext';
import { Context as RulesContext } from '../../hooks/contexts/RulesContext';
import { formatVersion, getSelectableLanguages } from '../../utils/version';
import { useLatestByCountryQuery } from '../../__generated__/types';

export const LanguageDropdown = ({ countryCode, refreshRedirect }: { countryCode: string; refreshRedirect?: boolean }) => {
  const { data } = useLatestByCountryQuery({ variables: { country: countryCode } });
  const formattedVersions = data?.latestVersionsForCountry?.map(formatVersion) ?? [];
  const selectableLanguages = getSelectableLanguages(formattedVersions, countryCode);
  const { selectedLanguage, setSelectedLanguage } = useLanguageSelection();
  const { setRefetchAfter } = useContext(RulesContext);
  return (
    <Styled.LanguageDropdown
      id="languageSelector"
      data-testid={'language-dropdown-select'}
      onChange={(e) => {
        setSelectedLanguage(e.target.value);
        if (refreshRedirect) {
          setRefetchAfter(`refresh-redirect-${e.target.value}`);
        }
      }}
      value={selectedLanguage}
    >
      <Option value={'*'} name={'All Languages'} />
      {selectableLanguages?.map(({ languageCode, languageName, countryCode }) => (
        <Option key={languageCode} value={`${countryCode}-${languageCode}`} name={languageName} />
      ))}
    </Styled.LanguageDropdown>
  );
};

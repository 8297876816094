import React, { useContext, SyntheticEvent, useState } from 'react';
import Button from '@ingka/button';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';
import chevronUp from '@ingka/ssr-icon/paths/chevron-up';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import { Context as MetaDataContext } from 'hooks/contexts/MetaDataContext';
import { Column as MetaDataTableColumn } from 'types';
import { MetaDataEntry } from '__generated__/types';
import { MetaDataCell } from './MetaDataCell';
import { MetaDataStrippedTableItem } from './MetaDataStrippedTableItem';
import * as Styled from './styles';
import { isPublicera } from '../../../opt-util/helpers';

interface MetaDataTableCollapsibleRowProps {
  isPlpPage: boolean;
  entry: MetaDataEntry;
  onMetadataDelete: (e: SyntheticEvent, entryId: string, pageTitle: string) => void;
  visibleColumns: MetaDataTableColumn[];
  type: string;
}

export const MetaDataTableCollapsibleRow: React.FC<MetaDataTableCollapsibleRowProps> = ({
  isPlpPage,
  entry,
  onMetadataDelete,
  visibleColumns,
  type,
}) => {
  const [expandedRowId, setExpandedRowId] = useState<string[]>([]);
  const [toggleChevron, setToggleChevron] = useState<boolean>(false);
  const { setIsEditorOpen } = useContext(MetaDataContext);
  const onRowToggle = (id: string) => {
    setToggleChevron(!toggleChevron);
    if (expandedRowId.includes(id)) {
      setExpandedRowId(expandedRowId.filter((rowId) => rowId !== id));
    } else {
      setExpandedRowId([...expandedRowId, id]);
    }
  };

  const onMetadataEditClick = (e: SyntheticEvent, entryId: string) => {
    e.stopPropagation();
    setIsEditorOpen(true, entryId);
  };

  return (
    <>
      <Styled.MetaDataCollapsedTableRow
        data-testid={`${entry.id}-row`}
        key={`${entry.id}-row`}
        onClick={() => onRowToggle(entry.id)}
        clickable={isPlpPage}
      >
        <td>{isPlpPage && <Styled.ChevronBtn iconOnly ssrIcon={expandedRowId.includes(entry.id) ? chevronUp : chevronDown} size={'small'} />}</td>
        {visibleColumns.map((column, index) => (
          <MetaDataCell key={index} entry={entry} type={type} column={column} />
        ))}
        <td>
          <Styled.MetaDataButtonWrapper>
            {!isPublicera(type) && (
              <Button
                type="tertiary"
                text={'Delete'}
                ssrIcon={trashCan}
                onClick={(e: SyntheticEvent) => onMetadataDelete(e, entry.id, entry.pageTitle || '')}
                data-testid={'meta-delete-button'}
                size={'small'}
              />
            )}

            <Button
              type="secondary"
              text={'Edit'}
              ssrIcon={pencil}
              onClick={(e: SyntheticEvent) => onMetadataEditClick(e, entry.id)}
              data-testid={'meta-edit-button'}
              size={'small'}
            />
          </Styled.MetaDataButtonWrapper>
        </td>
      </Styled.MetaDataCollapsedTableRow>
      {expandedRowId.includes(entry.id) && isPlpPage && (
        <MetaDataStrippedTableItem
          entryId={entry.id}
          searchClicks={entry.searchConsoleData?.search_clicks}
          searchCtr={entry.searchConsoleData?.search_ctr}
          searchImpressions={entry.searchConsoleData?.search_impressions}
          avgPosition={entry.searchConsoleData?.avg_position}
          suggestedPrimaryKeyword={entry.suggestedPrimaryKeyword}
          suggestedSecondaryKeyword={entry.suggestedSecondaryKeyword}
          suggestedAlternativeKeyword={entry.suggestedAlternativeKeyword}
        />
      )}
    </>
  );
};

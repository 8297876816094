import React, { useState } from 'react';
import * as Styled from './styles';
import Text from '@ingka/text';
import { FlexContainer } from '../../components/FlexContainer';
import Tabs, { Tab } from '@ingka/tabs';
import { StatusOverviewPanel } from './StatusOverviewPanel';
import { VersionOverview } from '../../types';

type StatusOverviewProps = {
  pageTypesMap: Record<string, string>;
  metadataPanelProps?: { formattedVersions: VersionOverview[] };
};

export const StatusOverview = ({ pageTypesMap, metadataPanelProps }: StatusOverviewProps) => {
  const [activeTabKey, setActiveTabKey] = useState(Object.keys(pageTypesMap)[0]);

  return (
    <Styled.StatusOverviewSection direction="column">
      <Text tagName={'h4'} headingSize={'xs'}>
        Status overview
      </Text>
      <FlexContainer direction={'column'}>
        <Tabs
          tabs={Object.entries(pageTypesMap).map(([type, label]) => (
            <Tab key={type} tabPanelId={type} text={label} />
          ))}
          tabPanels={<StatusOverviewPanel activeTab={activeTabKey} metadataPanelProps={metadataPanelProps} />}
          defaultActiveTab={activeTabKey}
          onTabChanged={(selectedTabKey) => {
            setActiveTabKey(selectedTabKey);
          }}
        />
      </FlexContainer>
    </Styled.StatusOverviewSection>
  );
};

import styled from '@emotion/styled';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  padding: 1rem;
  justify-content: space-between;

  & > *:nth-of-type(1) {
    flex: 1 0 70%;
  }

  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }

  .prop {
    display: flex;
    flex-direction: row;

    .label {
      font-weight: bold;
      flex: 0 0 150px;
    }

    .value {
      overflow-wrap: break-word;
      word-break: break-all;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

import { FlexContainer } from '../../components/FlexContainer';
import { DashboardCardProps, StatusOverviewCard } from './StatusOverviewCard';
import * as Styled from './styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { VersionOverview } from '../../types';
import { FilterInput } from '../../__generated__/types';
import { useLanguageSelection } from '../../hooks/contexts/LanguageContext';

const getTabsForSelectedVersionType = (versions: VersionOverview[], pushHistory: (path: string, filter: Partial<FilterInput>) => void) => {
  return versions.map(({ size, optimizedItems, needsReview, type, countryCode, languageCode }) => {
    const percentOptimized = size ? Math.round((optimizedItems / size) * 100) : 0;
    const countryPath = `${countryCode}/${languageCode}/${type === 'filter' ? type : type.toUpperCase()}`;
    const cards: DashboardCardProps[] = [
      {
        type: 'negative',
        title: 'Non optimized',
        text: `${size - optimizedItems}`,
        onClick: () => pushHistory(`/metadata/${countryPath}`, { optimized: false }),
      },
      {
        type: 'cautious',
        title: 'Needs review',
        text: `${needsReview}`,
        onClick: () => pushHistory(`/metadata/${countryPath}`, { needsReview: true }),
      },
      { type: 'positive', title: 'Total optimized', text: `${optimizedItems}` },
      { type: 'informative', title: 'Optimized', text: `${percentOptimized}%` },
    ];
    return { type, cards };
  });
};

const getSelectedVersionsForLanguage = (versions: VersionOverview[], selectedLanguage: string): VersionOverview[] => {
  if (selectedLanguage === '*') {
    const languageVersionMap = new Map<string, VersionOverview>();
    versions.forEach((version) => {
      const prevVersion = languageVersionMap.get(version.type);
      if (!prevVersion) {
        languageVersionMap.set(version.type, version);
      } else {
        const aggregatedVersion: VersionOverview = {
          ...prevVersion,
          size: prevVersion.size + version.size,
          optimizedItems: prevVersion.optimizedItems + version.optimizedItems,
          needsReview: prevVersion.needsReview + version.needsReview,
        };
        languageVersionMap.set(version.type, aggregatedVersion);
      }
    });
    return Array.from(languageVersionMap.values());
  }
  return versions.filter((version) => version.languageCode === selectedLanguage);
};

export const StatusOverviewMetadataCard = ({ activeTab, formattedVersions }: { activeTab: string; formattedVersions: VersionOverview[] }) => {
  const history = useHistory();
  const { selectedLanguage } = useLanguageSelection();

  const selectedVersions = getSelectedVersionsForLanguage(formattedVersions, selectedLanguage === '*' ? '*' : selectedLanguage.substring(3, 5));
  const metadataTabs = getTabsForSelectedVersionType(selectedVersions, history.push);
  return (
    <>
      {metadataTabs
        .filter((tab) => tab.type === activeTab)
        .map((metadataTab) => (
          <FlexContainer direction={'row'} gap={0.4} key={metadataTab.type}>
            {metadataTab.cards.map((props) => (
              <StatusOverviewCard key={props.type} {...props} />
            ))}
          </FlexContainer>
        ))}
      {!metadataTabs.some((tab) => tab.type === activeTab) && (
        <Styled.StatusOverviewPanelMissingDataWrapper>
          <FlexContainer justify="center">
            <h3>There is no data for {activeTab}</h3>
          </FlexContainer>
        </Styled.StatusOverviewPanelMissingDataWrapper>
      )}
    </>
  );
};

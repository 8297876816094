import React, { useContext, useState } from 'react';
import Hyperlink from '@ingka/hyperlink';
import { KeyValue } from 'components/common/KeyValue';
import { FlexContainer } from 'components/FlexContainer';
import { OmitTN } from 'opt-util/types';
import { MetaDataExtendedFragment } from '__generated__/types';
import { Config, filterPageConfig, pipConfig, plpConfig, publiceraConfig } from './EditorFieldConfig';
import { InfoBox } from './InfoBox';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import * as Styled from './styles';

export interface ItemProps {
  data: OmitTN<MetaDataExtendedFragment>;
  type: string;
  hideBadges?: boolean;
  author?: string;
}

export const getConfig = (type: string | undefined): Config[] => {
  if (!type) return [];
  return (
    {
      PLP: plpConfig,
      PIP: pipConfig,
      publicera: publiceraConfig,
      filter: filterPageConfig.map((item) => ({
        ...item,
        ariaLabelledby: item.key,
      })),
    }[type] ?? []
  );
};

const BodyCopy: React.FC<{ body: string }> = ({ body }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <Styled.BodyCopy isExpanded={isExpanded} dangerouslySetInnerHTML={{ __html: body }} />
      <Hyperlink
        onClick={(e: Event) => {
          e.preventDefault();
          setIsExpanded(!isExpanded);
        }}
      >
        {isExpanded ? 'Show Less' : 'Show more...'}
      </Hyperlink>
    </>
  );
};

export const View: React.FC<ItemProps> = ({ type, data, hideBadges, author }) => {
  const config = getConfig(type);
  const { isAdmin } = useContext(LoginContext);
  return (
    <FlexContainer direction={'column'} data-testid={data.isOptimized ? `metadata-${data.id}` : `catalog-${data.id}`}>
      <InfoBox entry={data} hideBadges={hideBadges || false} type={type} />
      {config
        .filter((c) => c.showInPreview)
        .map((c) => {
          if (c.key === 'descriptionIsSynced') return;
          if (c.key === 'canonical')
            return (
              <KeyValue
                key={data.id}
                label={c.label}
                value={
                  <Hyperlink key={c.label} target={'_blank'} url={data[c.key] || undefined}>
                    {data[c.key]}
                  </Hyperlink>
                }
              />
            );
          if (c.key === 'bodyCopy' || c.key === 'introText') {
            const html = data.mdToHtml && data.mdToHtml[c.key];
            if (!html) return null;
            const bodyCopy = c.key === 'bodyCopy' ? <BodyCopy body={html} /> : <div dangerouslySetInnerHTML={{ __html: html }} />;
            return <KeyValue key={c.key} label={c.label} value={bodyCopy} />;
          }
          if (c.subKey) {
            return <KeyValue key={c.key} label={c.label} value={data[c.key]?.[c.subKey]} />;
          }
          return <KeyValue key={c.key} label={c.label} value={data[c.key]} />;
        })}
      {data.ts && <KeyValue label={'Updated At'} value={new Date(data.ts).toLocaleString()} />}
      {isAdmin() && <KeyValue label={'Updated By'} value={author} />}
    </FlexContainer>
  );
};

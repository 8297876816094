import { createContext, useContext, useState, ReactNode } from 'react';

interface LanguageContextType {
  selectedLanguage: string;
  setSelectedLanguage: (selectedLanguage: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children, injectedLanguage }: { children: ReactNode; injectedLanguage?: string }) {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(injectedLanguage || '*');

  return <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>{children}</LanguageContext.Provider>;
}

export const useLanguageSelection = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
